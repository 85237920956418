(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@babylonjs/core"), require("@babylonjs/gui"), require("@babylonjs/loaders"), require("@babylonjs/materials"), require("@babylonjs/serializers"));
	else if(typeof define === 'function' && define.amd)
		define("babylonjs-inspector", ["@babylonjs/core", "@babylonjs/gui", "@babylonjs/loaders", "@babylonjs/materials", "@babylonjs/serializers"], factory);
	else if(typeof exports === 'object')
		exports["babylonjs-inspector"] = factory(require("@babylonjs/core"), require("@babylonjs/gui"), require("@babylonjs/loaders"), require("@babylonjs/materials"), require("@babylonjs/serializers"));
	else
		root["INSPECTOR"] = factory(root["BABYLON"], root["BABYLON"]["GUI"], root["BABYLON"], root["BABYLON"]["MATERIALS"], root["BABYLON"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), function(__WEBPACK_EXTERNAL_MODULE__babylonjs_core_Misc_observable__, __WEBPACK_EXTERNAL_MODULE__babylonjs_gui_2D_controls_image__, __WEBPACK_EXTERNAL_MODULE__babylonjs_loaders_glTF_index__, __WEBPACK_EXTERNAL_MODULE__babylonjs_materials_grid_gridMaterial__, __WEBPACK_EXTERNAL_MODULE__babylonjs_serializers_glTF_2_0_index__) {
return 